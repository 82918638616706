@font-face {
  font-family: 'icon_lawft';
  src: url('../fonts/icon_lawft.eot?lwt84o');
  src: url('../fonts/icon_lawft.eot?lwt84o#iefix') format('embedded-opentype'), url('../fonts/icon_lawft.ttf?lwt84o') format('truetype'),
    url('../fonts/icon_lawft.woff?lwt84o') format('woff'), url('../fonts/icon_lawft.svg?lwt84o#icon_lawft') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icon_lawft' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-save-icon:before {
  content: '\e992';
  color: #7f8f9f;
}
.icon-group-icon:before {
  content: '\e993';
  color: #7f8f9f;
}
.icon-textmessage:before {
  content: '\e990';
  color: #7f8f9f;
}
.icon-textmessage-filled:before {
  content: '\e991';
  color: #7f8f9f;
}

.icon-expenses:before {
  content: '\e98e';
  color: #7f8f9f;
}
.icon-expenses-filled:before {
  content: '\e98f';
  color: #7f8f9f;
}

.icon-personal-Report:before {
  content: '\e98a';
  color: #7f8f9f;
}
.icon-personal-Report-filled:before {
  content: '\e98b';
  color: #7f8f9f;
}
.icon-Report:before {
  content: '\e98c';
  color: #7f8f9f;
}
.icon-Report-filled:before {
  content: '\e98d';
  color: #7f8f9f;
}

.icon-subscription:before {
  content: '\e988';
  color: #7f8f9f;
}
.icon-subscription-filled:before {
  content: '\e989';
  color: #7f8f9f;
}
.icon-card:before {
  content: '\0024';
  color: #7f8f9f;
}
.icon-card-filled:before {
  content: '\0024';
  color: #7f8f9f;
}

.icon-png:before {
  content: '\e978';
  color: #7f8f9f;
}
.icon-pdf:before {
  content: '\e979';
  color: #7f8f9f;
}
.icon-jpg:before {
  content: '\e97a';
  color: #7f8f9f;
}
.icon-doc:before {
  content: '\e97b';
  color: #7f8f9f;
}
.icon-txt:before {
  content: '\e97c';
  color: #7f8f9f;
}
.icon-text-input:before {
  content: '\e97d';
  color: #7f8f9f;
}
.icon-eye-disable:before {
  content: '\e97e';
  color: #7f8f9f;
}
.icon-eye:before {
  content: '\e97f';
  color: #7f8f9f;
}
.icon-signatureN:before {
  content: '\e980';
  color: #7f8f9f;
}
.icon-play-btn:before {
  content: '\e981';
  color: #7f8f9f;
}
.icon-play-filled:before {
  content: '\e982';
  color: #7f8f9f;
}
.icon-play:before {
  content: '\e983';
  color: #7f8f9f;
}
.icon-nocontact:before {
  content: '\e984';
  color: #7f8f9f;
}
.icon-calculator:before {
  content: '\e985';
  color: #7f8f9f;
}
.icon-List-view:before {
  content: '\e986';
  color: #7f8f9f;
}
.icon-grid-view:before {
  content: '\e987';
  color: #7f8f9f;
}
.icon-custom-descriptors:before {
  content: '\e976';
  color: #7f8f9f;
}
.icon-custom-descriptors-filled:before {
  content: '\e977';
  color: #7f8f9f;
}
.icon-businessrole:before {
  content: '\e972';
  color: #7f8f9f;
}
.icon-businessrole-filled:before {
  content: '\e973';
  color: #7f8f9f;
}
.icon-globaltag:before {
  content: '\e974';
  color: #7f8f9f;
}
.icon-globaltag-filled:before {
  content: '\e975';
  color: #7f8f9f;
}
.icon-accounts:before {
  content: '\e963';
  color: #7f8f9f;
}
.icon-accounts-filled:before {
  content: '\e964';
  color: #7f8f9f;
}
.icon-automation:before {
  content: '\e965';
  color: #7f8f9f;
}
.icon-automation-filled:before {
  content: '\e966';
  color: #7f8f9f;
}
.icon-jobtitle:before {
  content: '\e967';
  color: #7f8f9f;
}
.icon-jobtitle-filled:before {
  content: '\e968';
  color: #7f8f9f;
}
.icon-leadsource:before {
  content: '\e969';
  color: #7f8f9f;
}
.icon-leadsource-filled:before {
  content: '\e96a';
  color: #7f8f9f;
}
.icon-leadstageN:before {
  content: '\e96b';
  color: #7f8f9f;
}
.icon-leadstageN-filled:before {
  content: '\e96c';
  color: #7f8f9f;
}
.icon-paymrntstructure:before {
  content: '\e96d';
  color: #7f8f9f;
}
.icon-paymrntstructure-filled:before {
  content: '\e96e';
  color: #7f8f9f;
}
.icon-relationrole:before {
  content: '\e96f';
  color: #7f8f9f;
}
.icon-relationrole-filled:before {
  content: '\e970';
  color: #7f8f9f;
}
.icon-signature:before {
  content: '\e971';
  color: #7f8f9f;
}
.icon-casedocument:before {
  content: '\e94f';
  color: #7f8f9f;
}
.icon-casedocument-filled:before {
  content: '\e950';
  color: #7f8f9f;
}
.icon-caseprogress:before {
  content: '\e951';
  color: #7f8f9f;
}
.icon-caseprogress-filled:before {
  content: '\e952';
  color: #7f8f9f;
}
.icon-Customfield:before {
  content: '\e953';
  color: #7f8f9f;
}
.icon-Customfield-filled:before {
  content: '\e954';
  color: #7f8f9f;
}
.icon-expensetype:before {
  content: '\e955';
  color: #7f8f9f;
}
.icon-expensetype-filled:before {
  content: '\e956';
  color: #7f8f9f;
}
.icon-leadstage:before {
  content: '\e957';
  color: #7f8f9f;
}
.icon-leadstage-filled:before {
  content: '\e958';
  color: #7f8f9f;
}
.icon-manageaccount:before {
  content: '\e959';
  color: #7f8f9f;
}
.icon-manageaccount-filled:before {
  content: '\e95a';
  color: #7f8f9f;
}
.icon-officeaddress:before {
  content: '\e95b';
  color: #7f8f9f;
}
.icon-officeaddress-filled:before {
  content: '\e95c';
  color: #7f8f9f;
}
.icon-practicearea:before {
  content: '\e95d';
  color: #7f8f9f;
}
.icon-practicearea-filled:before {
  content: '\e95e';
  color: #7f8f9f;
}
.icon-teammanagement:before {
  content: '\e95f';
  color: #7f8f9f;
}
.icon-teammanagement-filled:before {
  content: '\e960';
  color: #7f8f9f;
}
.icon-timemanager:before {
  content: '\e961';
  color: #7f8f9f;
}
.icon-timemanager-filled:before {
  content: '\e962';
  color: #7f8f9f;
}
.icon-activity:before {
  content: '\e900';
  color: #7f8f9f;
}
.icon-add:before {
  content: '\e901';
  color: #7f8f9f;
}
.icon-Alert:before {
  content: '\e902';
  color: #f8955d;
}
.icon-Alert {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
.icon-alphabet-ascending:before {
  content: '\e903';
  color: #7f8f9f;
}
.icon-alphabet-descending:before {
  content: '\e904';
  color: #7f8f9f;
}
.icon-arrow-up:before {
  content: '\e905';
  color: #7f8f9f;
}
.icon-arrow-up-down:before {
  content: '\e906';
  color: #7f8f9f;
}
.icon-back:before {
  content: '\e907';
  color: #7f8f9f;
}
.icon-calendar:before {
  content: '\e908';
  color: #7f8f9f;
}
.icon-callBack:before {
  content: '\e909';
  color: #7f8f9f;
}
.icon-chat:before {
  content: '\e90a';
  color: #7f8f9f;
}
.icon-checkbox:before {
  content: '\e90b';
  color: #7f8f9f;
}
.icon-clock:before {
  content: '\e90c';
  color: #7f8f9f;
}
.icon-Close:before {
  content: '\e90d';
  color: #94a3b2;
}
.icon-contacts:before {
  content: '\e90e';
  color: #7f8f9f;
}
.icon-delete:before {
  content: '\e90f';
  color: #ff583e;
}
.icon-doc-add:before {
  content: '\e910';
  color: #7f8f9f;
}
.icon-documentPrepare:before {
  content: '\e911';
  color: #7f8f9f;
}
.icon-doc-upload:before {
  content: '\e912';
  color: #7f8f9f;
}
.icon-dollar:before {
  content: '\e913';
  color: #7f8f9f;
}
.icon-done:before {
  content: '\e914';
  color: #7f8f9f;
}
.icon-dropdown:before {
  content: '\e915';
  color: #7f8f9f;
}
.icon-edit:before {
  content: '\e916';
  color: #7f8f9f;
}
.icon-Error:before {
  content: '\e917';
  color: #ff583e;
}
.icon-files:before {
  content: '\e918';
  color: #7f8f9f;
}
.icon-folder:before {
  content: '\e919';
  color: #7f8f9f;
}
.icon-information:before {
  content: '\e91a';
  color: #7f8f9f;
}
.icon-meeting:before {
  content: '\e91b';
  color: #7f8f9f;
}
.icon-messages:before {
  content: '\e91c';
  color: #7f8f9f;
}
.icon-money:before {
  content: '\e91d';
  color: #7f8f9f;
}
.icon-more:before {
  content: '\e91e';
  color: #7f8f9f;
}
.icon-notes:before {
  content: '\e91f';
  color: #7f8f9f;
}
.icon-notification:before {
  content: '\e920';
  color: #7f8f9f;
}
.icon-overview:before {
  content: '\e921';
  color: #7f8f9f;
}
.icon-percentage:before {
  content: '\e922';
  color: #7f8f9f;
}
.icon-person:before {
  content: '\e923';
  color: #7f8f9f;
}
.icon-Print:before {
  content: '\e924';
  color: #7f8f9f;
}
.icon-radio:before {
  content: '\e925';
  color: #7f8f9f;
}
.icon-save:before {
  content: '\e926';
  color: #7f8f9f;
}
.icon-search:before {
  content: '\e927';
  color: #7f8f9f;
}
.icon-send-form:before {
  content: '\e928';
  color: #7f8f9f;
}
.icon-Setting:before {
  content: '\e929';
  color: #7f8f9f;
}
.icon-shared:before {
  content: '\e92a';
  color: #7f8f9f;
}
.icon-sort:before {
  content: '\e92b';
  color: #7f8f9f;
}
.icon-sort-ascend .path1:before {
  content: '\e92c';
  color: rgb(127, 143, 159);
}
.icon-sort-ascend .path2:before {
  content: '\e92d';
  margin-left: -1em;
  color: rgb(175, 184, 192);
}
.icon-sort-descend .path1:before {
  content: '\e92e';
  color: rgb(175, 184, 192);
}
.icon-sort-descend .path2:before {
  content: '\e92f';
  margin-left: -1em;
  color: rgb(127, 143, 159);
}
.icon-succes:before {
  content: '\e930';
  color: #4ba457;
}
.icon-tasks:before {
  content: '\e931';
  color: #7f8f9f;
}
.icon-timer:before {
  content: '\e932';
  color: #7f8f9f;
}
.icon-timeStop:before {
  content: '\e933';
  color: #7f8f9f;
}
.icon-workload:before {
  content: '\e934';
  color: #7f8f9f;
}
.icon-zoom-in:before {
  content: '\e935';
  color: #7f8f9f;
}
.icon-zoom-out:before {
  content: '\e936';
  color: #7f8f9f;
}
.icon-activity-filled:before {
  content: '\e937';
  color: #7f8f9f;
}
.icon-business-filled:before {
  content: '\e938';
  color: #7f8f9f;
}
.icon-calendar-filled:before {
  content: '\e939';
  color: #7f8f9f;
}
.icon-callBack-filled:before {
  content: '\e93a';
  color: #7f8f9f;
}
.icon-chat-filled:before {
  content: '\e93b';
  color: #7f8f9f;
}
.icon-checkbox-filled:before {
  content: '\e93c';
  color: #7f8f9f;
}
.icon-check-filled-filled:before {
  content: '\e93d';
  color: #7f8f9f;
}
.icon-contacts-filled:before {
  content: '\e93e';
  color: #7f8f9f;
}
.icon-documentPrepare-filled:before {
  content: '\e93f';
  color: #7f8f9f;
}
.icon-files-filled:before {
  content: '\e940';
  color: #7f8f9f;
}
.icon-High-filled:before {
  content: '\e941';
  color: #ff583e;
}
.icon-information-filled:before {
  content: '\e942';
  color: #7f8f9f;
}
.icon-location-filled:before {
  content: '\e943';
  color: #7f8f9f;
}
.icon-Low-filled:before {
  content: '\e944';
  color: #4ba457;
}
.icon-Medium-filled:before {
  content: '\e945';
  color: #f8c100;
}
.icon-meeting-filled:before {
  content: '\e946';
  color: #7f8f9f;
}
.icon-messages-filled:before {
  content: '\e947';
  color: #7f8f9f;
}
.icon-money-filled:before {
  content: '\e948';
  color: #7f8f9f;
}
.icon-notes-filled:before {
  content: '\e949';
  color: #7f8f9f;
}
.icon-overview-filled:before {
  content: '\e94a';
  color: #7f8f9f;
}
.icon-person-filled:before {
  content: '\e94b';
  color: #7f8f9f;
}
.icon-tasks-filled:before {
  content: '\e94c';
  color: #7f8f9f;
}
.icon-timeStop-filled:before {
  content: '\e94d';
  color: #7f8f9f;
}
.icon-window-filled:before {
  content: '\e94e';
  color: #ff583e;
}
