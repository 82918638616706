// $gray-dark: #333;
// $gray: #666;
// $gray-light: #999;
// $gray-lighter: #ccc;
// $gray-lighter2: #eee;
// $gray-lightest: #f1f1f1;
// $blue: #71bbe4;
// // $blue:                      #4BABF9;
// $blue-light: #b2dffb;
// $blue-lightest: #e3f4ff;
// $primary-color: #124b69;
// $primary-color-dark: #0d3147;
// $primary-color-light: #0b628f;
// $llc: #63a7ff;
// $ltd: #3ddc92;
// $corporation: #c37af2;
// $llp: #03a0a0;
// $lllp: #fb66be;
// $pfc: #662d91;
// $prc: #c37af2;
// $npc: #9696d8;
// $past: #ff5f58;
// $folder-yellow: #fdb900;
// // $acknowledged:              #5abd4c;
// $gobeaglefiling: #5abd4c;
// // $upcoming:                  #F5C616;
// $upcoming: #efc10a;
// //  $upcoming:                 #FFB607;
// $brand-color: #c49557;
// $nav-color: #0458b0;
// $nav-hover: #387cf6;
// $green-light: #29c292;

// $font-prime: "Century Gothic", "CenturyGothic", "AppleGothic", sans-serif;
// $font-second: "ariel", sans-serif;
// $danger-alert: #ce0606;
// $bg-latest: #f5f5f5;
// $green: #0d950d;
// $blue-light: #a1ddfd;

// $font-prime: "Century Gothic", "CenturyGothic", "AppleGothic", sans-serif;

@font-face {
    font-family: "AvenirNext";
    src: url("../fonts/AvenirNextCyr-Demi.ttf") format('truetype');
    src: url("../fonts/AvenirNextCyr-Regular.ttf") format('truetype');
    src: url("../fonts/AvenirNextCyr-Medium.ttf") format('truetype');
    src: url("../fonts/AvenirNextCyr-Demi.woff") format('woff');
    src: url("../fonts/AvenirNextCyr-Medium.woff") format('woff');
    src: url("../fonts/AvenirNextCyr-Regular.woff") format('woff');
    src: url("../fonts/AvenirNextCyr-Demi.woff") format('woff');
    src: url("../fonts/AvenirNextCyr-Medium.woff") format('woff');
    src: url("../fonts/AvenirNextCyr-Regular.woff") format('woff');
}

$primary-font: "AvenirNextR";

@font-face {
    font-family: "AvenirNextD";
    src: url("../fonts/a9817c16-2a79-49bf-8543-6c846d73a52f.woff") format('woff');
    src: url("../fonts/6afe4676-059a-4aa8-b891-29856bbcba22.woff2") format('woff2');
}
@font-face {
    font-family: "AvenirNextM";
    src: url("../fonts/72556e8c-c677-48d8-81ff-3eb8fc50033b.woff") format('woff');
    src: url("../fonts/f5d4f93b-454a-43e0-9ae8-7f1271b5c0fd.woff2") format('woff2');
}

@font-face {
    font-family: "AvenirNextR";
    src: url("../fonts/a1adabe6-efe5-40b7-b619-48111c8ff883.woff") format('woff');
    src: url("../fonts/c4691e94-080d-4452-8f9f-8b3db6b68d55.woff2") format('woff2');
   
}



$primary-main:#517DDA;
$primary-middle:#2B5ECA;
$primary-dark:#2655B9;
$primary-bg:#EFF4FF;
$success:#4BA457;
$success-bg:#EDFAEF;
$error:#F06954;
$sec-error:#FFF5F2;
$background: #FAFBFE;
$tag:#48515B;
$icon-red:#FF583E;

$white:    #fff;
$black-100: #F0F2F5;
$black-200: #AFB8C0;
$black-300: #94A3B2;
$black-400: #7F8F9F;
$black-500: #677A8E;
$black-600: #5A6672;
$black-700: #48515B;
$black-800: #333C47;
$black-900: #171E27;
$black:    #000;


$turquoise: #45C2D9;
$orangy: #F8955D;
$yellow:#F2DF32;
$violet: #8D73EF;
$lemon:#F8C100;

$size-4:4px;
$size-8:8px;
$size-12:12px;
$size-14:14px;
$size-16:16px;
$size-20:20px;
$size-24:24px;

$shadow-sm: 0px 1px 3px rgba(103, 122, 142, 0.24);
$shadow-md: 0px 2px 6px rgba(103, 122, 142, 0.24);
$shadow-lg: 0px 4px 12px rgba(103, 122, 142, 0.24);

