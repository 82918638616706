// @mixin fade-out {
//     -webkit-animation-name: fadeOut;
//     -webkit-animation-duration: 1s;
//     animation-name: fadeOut;
//     animation-duration: 1s;
// }
// @mixin form-label{
//     margin-bottom: .25rem;
//     color: #6c757d;
//     font-size: 80%;
//     font-weight: 400;
//   }


//   @mixin border-default($color:#124B69, $width: 1px) {
//     border: $width solid $color;
//   }
// @mixin p-button-primary{
//     background-color: $primary-main!important;
//     border: 1px solid $primary-main!important;
//     border-radius: 12px;
//   }
@mixin primary-font {
    font-family: $primary-font!important;
}
@mixin rounded-7{
    border-radius:7px;
}
@mixin rounded-8{
    border-radius:8px;
}
@mixin rounded-10{
    border-radius:10px !important;
}
@mixin rounded-12{
    border-radius:12px;
}
@mixin button-text {
    background-color: none;
    font-size: 12px;
    color: $primary-dark;
}
@mixin border-0 {
    border: none;
}
@mixin h-100 {
    height: 100%;
}
@mixin text-primary-main{
    color: $primary-main;
}
@mixin text-primary-middle{
    color: $primary-middle;
}
@mixin text-primary-dark{
    color: $primary-dark;
}
// $primary-main:#517DDA;
// $primary-middle:#2B5ECA;
// $primary-dark:#2655B9;
// $primary-bg:#EFF4FF;
// $success:#4BA457;
// $success-bg:#EDFAEF;
// $error:#F06954;
// $background: #FAFBFE;
@mixin shadow{
    background: $white !important;
    border-radius: $size-16 !important;
}
@mixin background{
    background-color: $background;
}
// @mixin shadow-middle{
//     background: #FFFFFF;
//     box-shadow: 0px 2px 6px rgba(103, 122, 142, 0.24);
//     border-radius: 16px;
// }
// @mixin shadow-big{
//     background: #FFFFFF;
//     box-shadow: 0px 4px 12px rgba(103, 122, 142, 0.24);
//     border-radius: 16px;
// }

//   @mixin p-column-title{
//     font-style: normal;
//     font-weight: 500;
//     font-size: 12px;
//     line-height: 16px;
    
//     /* identical to box height, or 133% */
//     letter-spacing: 0.01em;
    
//     /* black/500 */
//     color: $gray-500;
//   }