.upload-window {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 300px;
  background: #fff;
  border: 1px solid #ccc;
  z-index: 1000;
  transition: transform 0.3s ease;
  padding: 15px;
  box-shadow: 0px 2px 6px rgba(103, 122, 142, 0.24);
  border-radius: 15px;
}

.minimized .upload-body {
  height: 0 !important;
  overflow: hidden;
}

.upload-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.upload-body {
  transition: transform 0.3s ease;
  border-top: 1px solid #ccc;
  padding: 10px 0;
}

.upload-item {
  margin-bottom: 10px;
}

.upload-window .p-progressbar-label {
  line-height: 0.8rem !important;
  font-size: smaller;
  color: white !important;
}

.file-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.file-name {
  margin: 0;
  font-weight: bold;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
}

.file-status {
  margin: 0;
  font-size: 0.8em;
  color: #666;
}

.progress-bar {
  margin-top: 5px;
  height: 10px;
}

.file-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}
