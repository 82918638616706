.dot-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 11px;
}

.dot {
  height: 11px;
  width: 11px;
  border-radius: 50%;
  cursor: pointer;
}

.positive {
  background-color: #6dc05f;
}

.neutral {
  background-color: #c7d707;
}

.negative {
  background-color: #d86765;
}

.mixed {
  background-color: #6988a4;
}

.tooltip-header {
  font-weight: 600;
  // font-size: 14px;
}

.tooltip-item {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  width: 350px;
  font-size: 12px;
}

.tooltip-item .dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 5px;
}
